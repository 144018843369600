import React, { useReducer, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { navigate, useLocation } from '@reach/router';
import CustomExpandIcon from '../components/icon/custom-expand-icon';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Input from '../components/input';
import {
  initialState,
  FAQSearchReducer,
  handleSearch,
} from '../components/faq/utils/FAQSearchState';
import { IFaq } from '../components/GlobalState';

// margin: 2rem 140px 96px 140px;
// padding: 96px 32px 0 32px;
const Container = styled('div')`
  border-radius: 10px;
  padding: 0 24px;
  margin-left: 70px;
  margin-right: 70px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0;
  }
`;

const CustomH2 = styled('h2')`
  font-family: 'HCo Gotham SSm';
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const FAQAnswer = styled('div')`
  font-family: 'HCo Gotham SSm';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 0;
  padding-right: 48px;
`;

const CustomH1 = styled('h1')`
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 48px;
  margin-top: 48px;
`;

const SearchInputContainer = styled('div')`
  margin-right: 24px;
`;

const CustomInput = styled(Input)`
  margin-bottom: 1.5rem;
`;
export const FAQ: React.FC = () => {
  let idpath = '';
  const location = useLocation();
  const path = location.hash;
  if (path !== '') {
    idpath = path.slice(path.indexOf('#') + 1, path.length); // Get rid of /#
  }
  const [state, dispatch] = useReducer(FAQSearchReducer, initialState);
  const [expanded, setExpanded] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleVisibilityToggle = () => setOpen(!open);
  const handleChange = (id) => (event, isExpanded: any) => {
    setExpanded(isExpanded ? id : null);
    if (id === idpath || idpath !== '') {
      navigate(`/faq#l`);
    }
  };

  const queryResults = useStaticQuery(graphql`
    query FaqPage {
      allGraphCmsFaq {
        nodes {
          faqs {
            ... on GraphCMS_FaqComponent {
              id
              category
              question
              answer {
                html
              }
            }
          }
        }
      }
    }
  `);
  const faqs = queryResults?.allGraphCmsFaq?.nodes?.[0]?.faqs;
  useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: faqs });
  }, []);

  const articles =
    state.search?.length >= 3
      ? state.searchResults?.map((faq: IFaq) => (
          <div style={{ marginRight: '25px' }} key={faq?.id}>
            <Accordion
              onClick={handleVisibilityToggle}
              onChange={handleChange(faq?.id)}
              expanded={expanded === faq?.id || idpath === faq?.id}
              style={{
                backgroundColor: 'transparent',
                backgroundImage: 'none',
              }}
            >
              <AccordionSummary
                style={{ padding: '0px' }}
                expandIcon={<CustomExpandIcon />}
                aria-controls={faq?.id}
                aria-expanded={open}
                id={faq?.id}
              >
                <CustomH2 dangerouslySetInnerHTML={{ __html: faq?.question }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px' }}>
                <FAQAnswer
                  dangerouslySetInnerHTML={{ __html: faq?.answer?.html }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      : state.data?.map((faq: IFaq) => (
          <div style={{ marginRight: '25px' }} key={faq?.id}>
            <Accordion
              onChange={handleChange(faq?.id)}
              onClick={handleVisibilityToggle}
              expanded={expanded === faq?.id || idpath === faq?.id}
              style={{
                backgroundColor: 'transparent',
                backgroundImage: 'none',
              }}
            >
              <AccordionSummary
                id={faq?.id}
                style={{ padding: '0px' }}
                expandIcon={<CustomExpandIcon />}
                aria-controls={faq?.id}
                aria-expanded={open}
              >
                <CustomH2 dangerouslySetInnerHTML={{ __html: faq?.question }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px' }}>
                <FAQAnswer
                  dangerouslySetInnerHTML={{ __html: faq?.answer?.html }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        ));
  return (
    <Layout>
      <SEO title="FAQs" description="FAQs" />
      <Container>
        <CustomH1>Frequently Asked Questions</CustomH1>
        <SearchInputContainer>
          <CustomInput
            placeholder="Search FAQ"
            showEndAdornments={false}
            onChange={(event) => handleSearch(event, dispatch, state)}
          />
        </SearchInputContainer>
        {state.data.length === 0
          ? faqs.map((faq: IFaq) => (
              <div style={{ marginRight: '25px' }} key={faq?.id}>
                <Accordion
                  onClick={handleVisibilityToggle}
                  onChange={handleChange(faq?.id)}
                  expanded={expanded === faq?.id || idpath === faq?.id}
                  style={{
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',
                  }}
                >
                  <AccordionSummary
                    style={{ padding: '0px' }}
                    expandIcon={<CustomExpandIcon />}
                    aria-controls={faq?.id}
                    aria-expanded={open}
                    id={faq?.id}
                  >
                    <CustomH2
                      dangerouslySetInnerHTML={{ __html: faq?.question }}
                    />
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px' }}>
                    <FAQAnswer
                      dangerouslySetInnerHTML={{ __html: faq?.answer?.html }}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          : articles}
      </Container>
    </Layout>
  );
};

export default FAQ;
