import * as React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';

const CustomExpandIcon = () => (
  <Box
    sx={{
      '.Mui-expanded & > .collapsIconWrapper': {
        display: 'none',
      },
      '.expandIconWrapper': {
        display: 'none',
      },
      '.Mui-expanded & > .expandIconWrapper': {
        display: 'block',
      },
    }}
  >
    <div className="expandIconWrapper">
      <RemoveCircleOutlineIcon />
    </div>
    <div className="collapsIconWrapper">
      <AddCircleOutlineIcon />
    </div>
  </Box>
);

export default CustomExpandIcon;
